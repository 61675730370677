<template>
  <div>
    <v-expansion-panels class="mb-4">
      <v-expansion-panel v-for="(category, i) in categories" :key="i">
        <v-expansion-panel-header color="grey600">
          <span v-if="category.name">
            <span class="font-weight-semibold  mr-1" v-text="category.name" />
            <span class="text--disabled">({{ category.slug }})</span>
          </span>
          <span v-else class="text--disabled">Untitled</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="grey600">
          <v-form class="mb-4" @submit.prevent>
            <v-row dense>
              <v-col cols="12" md="6">
                <fieldset>
                  <label class="text-body-2 d-block mb-1">Name</label>
                  <v-text-field
                    v-model="category.name" filled dense color="primary500"
                    placeholder="Tech"
                  />
                </fieldset>
              </v-col>

              <v-col cols="12" md="6">
                <fieldset>
                  <label class="text-body-2 d-block mb-1">Slug</label>
                  <v-text-field
                    v-model="category.slug" filled dense color="primary500"
                    placeholder="tech"
                    :rules="[v => /^[a-z0-9_-]+$/.test(v) || 'Invalid slug: a-z, 0-9, _, -']"
                  />
                </fieldset>
              </v-col>
            </v-row>
          </v-form>

          <div class="text-right">
            <v-btn
              color="red" class="text-none" small
              :disabled="loading"
              @click="onDelete(i)"
            >
              <v-icon small class="mr-1">
                mdi-trash
              </v-icon>
              Delete
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="text-right">
      <v-btn
        color="grey100" outlined class="text-none mr-2"
        small :disabled="loading" @click="onAdd"
      >
        <v-icon small class="mr-1">
          mdi-plus
        </v-icon>
        Add new
      </v-btn>

      <v-btn
        :color="hasChanges ? 'green--gradient' : 'grey200'" class="text-none" light
        small :loading="loading"
        @click="onSave"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
const DEFAULT_FORM = () => ({
  name: '',
  slug: '',
})

export default {
  props: {
    loading: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      categories: [],
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.value) !== JSON.stringify(this.categories)
    },
  },
  watch: {
    value: {
      handler(val) {
        this.categories = structuredClone(val)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onAdd() {
      this.categories.push(DEFAULT_FORM())
    },
    onDelete(i) {
      this.categories.splice(i, 1)
    },
    onSave() {
      this.$emit('save', this.categories)
    },
  },
}
</script>
