<template>
  <v-container class="is-md py-12 px-2">
    <h3 class="text-h6 font-weight-bold">
      Box settings
    </h3>
    <p class="text-caption mb-6">
      Manage item rarities and categories
    </p>

    <v-row>
      <v-col cols="12">
        <v-card class="pa-4" color="grey800">
          <h4 class="text-body-1 primary500--text font-weight-bold">
            Box categories
          </h4>
          <p class="text-caption">
            View and edit box categories
          </p>

          <Categories
            :value="categories"
            :loading="loading.categories"
            @save="onSetCategories"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import Categories from './components/categories'

export default {
  metaInfo: {
    title: 'Box categories',
  },
  components: {
    Categories,
  },
  data() {
    return {
      loading: {
        categories: false,
      },
      categories: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  async created() {
    this.onGetCategories()
  },
  methods: {
    async onGetCategories() {
      try {
        this.loading.categories = true
        this.categories = await this.$socket.request('admin.cases.categories')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.categories = false
      }
    },
    async onSetCategories(categories) {
      try {
        this.loading.categories = true
        await this.$socket.request('admin.cases.categories.set', categories.map(i => ({
          _id: i._id,
          slug: i.slug?.toLowerCase(),
          name: i.name,
        })))
        this.$toast.success('Categories saved')
        this.onGetCategories()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.categories = false
      }
    },
  },
}
</script>
